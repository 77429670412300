import React from "react";

import { graphql } from "gatsby";

import News from "../../components/pages/News";

const NewsIndexPage = (props) => <News {...props} />;
export default NewsIndexPage;

export const query = graphql`
    query {
        laravelLighthouse {
            articles: newsArticles(
                orderBy: { order: DESC, column: DATE_PUBLISHED }
                isPublished: true
                source: "hoyer.de"
            ) {
                id
                abstract
                articleBody
                createdAt
                updatedAt
                datePublished
                image
                keywords
                section
                slug
                title
                titleAlignment
                video
            }
        }
        page: pagesYaml(templateKey: { eq: "pages/news" }) {
            ...Page
            startCount
            loadCount
            headline
            elements {
                ...EntireElement
            }
        }
    }
`;
